import React, { FC } from 'react'

import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import NFAccordion from 'gatsby-theme-nurofen/src/components/common/NFAccordion'
import UniversalImage from 'gatsby-theme-nurofen/src/components/common/UniversalImage'
import DangerouslySetInnerHtml from 'gatsby-theme-nurofen/src/components/common/DangerouslySetInnerHtml'
import ProductDosageCalc from 'gatsby-theme-nurofen/src/components/ProductDosageCalc'
import Branded from 'gatsby-theme-nurofen/src/components/common/Branded'

import { ProductDetailsProps } from 'gatsby-theme-nurofen/src/components/ProductDetails/model'
import 'gatsby-theme-nurofen/src/components/ProductDetails/ProductDetails.scss'

const ProductDetails: FC<ProductDetailsProps> = React.memo(
  ({
    brands,
    accordionItems,
    accordionDownloadSection,
    medicalInfo,
    dosageCalc,
  }) => {
    const accordionDownloadSectionProps =
      accordionDownloadSection?.[0]?.properties
    const pdfDownloadAction = accordionDownloadSectionProps?.accordionDownloadIconAction?.includes(
      "download"
    )
      ? true
      : !accordionDownloadSectionProps?.accordionDownloadIconAction
    const pdfOpenInTabAction = !!accordionDownloadSectionProps?.accordionDownloadIconAction?.includes(
      "open"
    )

    const downloadIcon = accordionDownloadSectionProps?.accordionDownloadIcon
      ?.svg?.content ? (
      <DangerouslySetInnerHtml
        className="text-logo"
        html={accordionDownloadSectionProps.accordionDownloadIcon.svg.content}
      />
    ) : accordionDownloadSectionProps?.accordionDownloadIcon ? (
      <UniversalImage
        img={accordionDownloadSectionProps.accordionDownloadIcon}
        imageAlt={accordionDownloadSectionProps.accordionDownloadIconAlt}
        imgStyle={{ objectFit: "contain" }}
      />
    ) : null

    const dosageCalcVariants = dosageCalc?.variants?.[0]?.properties?.dosageCalcDropdown?.map(
      ({
        properties: { dropdownOption, singleDosageValue, maxDosageValue },
      }) => ({
        dropdownOption,
        singleDosageValue,
        maxDosageValue,
      })
    )

    return (
      <div className="nf-product-details" data-test="ProductDetails">
        <Container fluid>
          <Row>
            <Col lg={4}>
              <Branded brands={brands} />
            </Col>
            <Col lg={8}>
              {dosageCalc?.labels && dosageCalcVariants?.length ? (
                <ProductDosageCalc
                  variants={dosageCalcVariants}
                  labels={dosageCalc.labels}
                />
              ) : null}
              {accordionItems ? (
                <NFAccordion
                  items={accordionItems}
                  selectedIndex={
                    accordionItems.find(item => item.isOpened === "1")?.index ||
                    0
                  }
                  scrollTabIntoView
                />
              ) : null}
              {accordionDownloadSectionProps ? (
                <div className="accordion-download-section">
                  <a
                    className="accordion-download-section--link"
                    href={
                      accordionDownloadSectionProps.accordionLinkToPDF
                        .gatsbyDocFile.relativePath
                    }
                    {...(pdfDownloadAction
                      ? { download: accordionDownloadSectionProps.documentName }
                      : {})}
                    {...(pdfOpenInTabAction ? { target: "_blank" } : {})}
                  >
                    <span>
                      {accordionDownloadSectionProps.accordionDownloadLabel}
                    </span>
                    {downloadIcon}
                  </a>
                </div>
              ) : null}
              {medicalInfo?.length ? (
                <div className="medical-info-section">
                  <h5>{medicalInfo[0].properties.title}</h5>
                  <DangerouslySetInnerHtml
                    html={medicalInfo[0].properties.bodyText}
                  />
                </div>
              ) : null}
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
)
export default ProductDetails
